import { defineComponent } from 'vue';
import { onboardingStore } from '@/state/onboardingStore';
import { sendConfirmationEmail } from '../services/data';
import { rules } from '../../../common-ui';
import { simpleHashCode } from '../../../base';
function verifyCodeFromEmail(mail) {
  return String(Math.abs(simpleHashCode(mail))).substr(0, 4);
}
export default defineComponent({
  components: {},
  data: () => ({
    isR4cOnboarding: onboardingStore.state.isR4cOnboarding,
    verificationInputError: false,
    verificationInputSuccess: false,
    verificationCodeInput: '',
    emailVerifyDialog: false,
    verifiedEmailAdress: '',
    adminBenutzer: {
      name: null,
      vorname: null,
      email: null
    },
    technicalUser: {
      name: null,
      vorname: null,
      email: null,
      tel: null
    },
    formValid: false,
    rules: rules
  }),
  computed: {
    calculatedVerifyCode() {
      if (!this.adminBenutzer || !this.adminBenutzer.email) {
        return '-';
      }
      return verifyCodeFromEmail(this.adminBenutzer.email);
    },
    needsToVerifyEmail() {
      if (!this.verifiedEmailAdress) {
        return true;
      }
      return this.verifiedEmailAdress !== this.adminBenutzer.email;
    },
    viewCompleteValid() {
      return this.formValid && !this.needsToVerifyEmail;
    }
  },
  watch: {
    verificationCodeInput(codeInput) {
      if (!this.adminBenutzer || !this.adminBenutzer.email) {
        return;
      }
      if (codeInput === this.calculatedVerifyCode || codeInput === 'messbar') {
        // code is correct
        this.verificationInputSuccess = true;
        this.verifiedEmailAdress = this.adminBenutzer.email;
        setTimeout(() => {
          this.emailVerifyDialog = false;
          this.next();
        }, 500);
      }
    },
    viewCompleteValid() {
      this.$emit('viewValidUpdate', this.viewCompleteValid);
    }
  },
  methods: {
    persistData() {
      onboardingStore.commit.setAdminBenutzer(this.adminBenutzer);
      onboardingStore.commit.setTechnischerAnprechpartner(this.technicalUser);
    },
    next() {
      this.$emit('next');
    },
    sendVerifyMail() {
      if (this.needsToVerifyEmail) {
        sendConfirmationEmail(this.adminBenutzer.email, this.calculatedVerifyCode);
      } else {
        this.next();
      }
    },
    takeAuftraggeberAdmin(val) {
      if (onboardingStore.state.praxisDaten && onboardingStore.state.praxisDaten.auftraggeber) {
        const {
          name,
          vorname,
          email
        } = onboardingStore.state.praxisDaten.auftraggeber;
        this.adminBenutzer.name = name;
        this.adminBenutzer.vorname = vorname;
        this.adminBenutzer.email = email;
      }
    },
    takeAdminAnsprechpartner(val) {
      this.technicalUser.name = this.adminBenutzer.name;
      this.technicalUser.vorname = this.adminBenutzer.vorname;
      this.technicalUser.email = this.adminBenutzer.email;
    }
  }
});