import { defineComponent } from 'vue';
import { onboardingStore } from '@/state/onboardingStore';
import { rules } from '../../../common-ui';
import { isEmpty } from 'lodash';
export default defineComponent({
  data: () => ({
    isR4cOnboarding: onboardingStore.state.isR4cOnboarding,
    abweichendeVersandadresse: false,
    praxisDaten: {
      auftraggeber: {
        name: null,
        vorname: null,
        email: null,
        straße: null,
        stadt: null,
        plz: null
      },
      praxis: {
        name: null,
        nameKurz: null,
        tel: null,
        straße: null,
        stadt: null,
        plz: null
      }
    },
    formValid: false,
    rules: rules,
    pvs: 'unknown',
    pvsValid: false,
    empfehlungsAuswahl: ['Kollege', 'Mitarbeiter', 'Factoring-Anbieter', 'Social-Media', 'Websuche', 'solutio', 'EVIDENT', 'Sonstiges'],
    empfehlungSonstiges: ''
  }),
  computed: {
    viewIsValid() {
      return this.formValid && (this.pvsValid || onboardingStore.state.isR4cOnboarding);
    },
    activeStep() {
      return onboardingStore.state.activeStep;
    },
    empfehlung() {
      return this.praxisDaten.empfehlung;
    }
  },
  watch: {
    viewIsValid() {
      this.$emit('viewValidUpdate', this.viewIsValid);
    },
    pvs() {
      if (this.pvs && this.pvs !== 'unknown') {
        this.pvsValid = true;
      }
    },
    empfehlung() {
      if (this.praxisDaten.empfehlung !== 'Sonstiges') {
        this.empfehlungSonstiges = '';
      }
    },
    activeStep() {
      var _a;
      if (((_a = this.activeStep) === null || _a === void 0 ? void 0 : _a.name) === 'Praxisdaten' && !isEmpty(this.empfehlungSonstiges)) {
        this.praxisDaten.empfehlung = 'Sonstiges';
      }
    }
  },
  methods: {
    next() {
      this.$emit('next');
    },
    persistData() {
      if (this.praxisDaten.empfehlung === 'Sonstiges') {
        this.praxisDaten.empfehlung = this.empfehlungSonstiges;
      }
      onboardingStore.commit.setPraxisdaten(this.praxisDaten);
      onboardingStore.commit.setLizenzInfo({
        pvs: this.pvs
      });
    },
    refreshHook() {
      var _a;
      if (onboardingStore.state.isR4cOnboarding && onboardingStore.state.charlyPraxisInfo) {
        const praxisDatenForm = this.praxisDaten.praxis;
        praxisDatenForm.name = praxisDatenForm.name || onboardingStore.state.charlyPraxisInfo.praxis;
        praxisDatenForm.nameKurz = praxisDatenForm.nameKurz || onboardingStore.state.charlyPraxisInfo.praxis;
        praxisDatenForm.tel = praxisDatenForm.tel || onboardingStore.state.charlyPraxisInfo.telefon;
        praxisDatenForm.straße = praxisDatenForm.straße || onboardingStore.state.charlyPraxisInfo.stempelstrasse;
        praxisDatenForm.stadt = praxisDatenForm.stadt || onboardingStore.state.charlyPraxisInfo.stempelort;
        praxisDatenForm.plz = praxisDatenForm.plz || onboardingStore.state.charlyPraxisInfo.stempelplz;
        const auftraggeberForm = this.praxisDaten.auftraggeber;
        auftraggeberForm.name = auftraggeberForm.name || onboardingStore.state.charlyPraxisInfo.stempelname1;
        auftraggeberForm.vorname = auftraggeberForm.vorname || onboardingStore.state.charlyPraxisInfo.stempelname2;
        auftraggeberForm.straße = auftraggeberForm.straße || onboardingStore.state.charlyPraxisInfo.stempelstrasse;
        auftraggeberForm.stadt = auftraggeberForm.stadt || onboardingStore.state.charlyPraxisInfo.stempelort;
        auftraggeberForm.plz = auftraggeberForm.plz || onboardingStore.state.charlyPraxisInfo.stempelplz;
        auftraggeberForm.email = auftraggeberForm.email || onboardingStore.state.charlyPraxisInfo.email;
        this.praxisDaten.empfehlung = (_a = onboardingStore.state.praxisDaten) === null || _a === void 0 ? void 0 : _a.empfehlung;
      }
    }
  }
});