import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import { defineComponent } from 'vue';
import { onboardingStore } from '@/state/onboardingStore';
export default defineComponent({
  props: ['stepId'],
  data: () => ({}),
  computed: {
    viewNeedsEdit() {
      const step = onboardingStore.getters.shownStepsFlow.find(entry => entry.stepId === this.stepId);
      return (step === null || step === void 0 ? void 0 : step.complete) === false;
    }
  },
  methods: {
    jumpToView() {
      onboardingStore.commit.setActiveStep(this.stepId);
    }
  }
});