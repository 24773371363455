import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap",
    attrs: {
      "id": "wrap"
    }
  }, [_vm.editR4cApps ? _c('h3', [_c('span', [_vm._v("rose4charly - r4c")]), _c('span', {
    staticClass: "edit-hint"
  }, [_vm._v("(Abos bearbeiten)")])]) : _vm._e(), _vm.editR4cApps ? _c('div', {
    staticClass: "marketing-text mt-4 mb-4"
  }, [_c('p', [_vm._v("Unsere rose4charly Apps kannst nach deinen individuellen Anforderungen und Bedürfnissen freischalten und verwenden. Lass dich von der Zeitersparnis und Arbeitserleichterung begeistern und probiere es einfach selber aus. Sollte eine App einmal nicht deinen Vorstellungen entsprechen, kannst du die Nutzung jeweils zum Ende des Monats einfach wieder beenden.")])]) : _vm._e(), _c('div', {
    staticClass: "paket-chooser"
  }, [_c(VTabs, {
    staticClass: "invertedTabs underlinedTabs",
    model: {
      value: _vm.currentTabIdx,
      callback: function ($$v) {
        _vm.currentTabIdx = $$v;
      },
      expression: "currentTabIdx"
    }
  }, [!_vm.editR4cApps ? _c(VTab, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("fas fa-stars")]), _c('span', [_vm._v("Highlights")])], 1) : _vm._e(), _vm._l(_vm.paketeR4cToShow, function (app) {
    return _c(VTab, {
      key: app.id,
      attrs: {
        "id": app.id
      }
    }, [app.id === "factoring" ? _c('div', {
      staticClass: "tab-icon"
    }, [_vm.hasSelectedFeatures(app.id) ? [_c('div', {
      staticClass: "fa-stack"
    }, [_c('i', {
      staticClass: "fa-duotone fa-shield fa-stack-2x"
    }), _c('i', {
      staticClass: "fa fa-euro-sign fa-stack-1x"
    })])] : [_c('div', {
      staticClass: "fa-stack"
    }, [_c('i', {
      staticClass: "fa-regular fa-shield fa-stack-2x"
    }), _c('i', {
      staticClass: "fa fa-euro-sign fa-stack-1x"
    })])]], 2) : app.id === "anamnese" ? _c('div', {
      staticClass: "tab-icon"
    }, [_vm.hasSelectedFeatures(app.id) ? [_c('i', {
      staticClass: "fa-duotone fa-2x fa-clipboard-medical"
    })] : [_c('i', {
      staticClass: "fa-regular fa-2x fa-clipboard-medical"
    })]], 2) : _vm._e(), app.id === "documents" ? _c('div', {
      staticClass: "tab-icon"
    }, [_vm.hasSelectedFeatures(app.id) ? [_c('i', {
      staticClass: "fa-duotone fa-2x fa-file-contract"
    })] : [_c('i', {
      staticClass: "fa-regular fa-2x fa-file-contract"
    })]], 2) : _vm._e(), _c('span', [_vm._v(_vm._s(app.name))])]);
  })], 2), _vm.currentTab === "highlights" ? _c('div', {
    staticClass: "app main"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "pakete-neu",
    attrs: {
      "id": "choices-main"
    }
  }, [_c('div', {
    staticClass: "choice-wrapper"
  }, [_c('div', {
    staticClass: "choice",
    attrs: {
      "data-testid": "highlights-megabundle"
    },
    on: {
      "click": function ($event) {
        return _vm.selectFactoringAndAnamnese();
      }
    }
  }, [_vm._m(2), _c('div', {
    staticClass: "beschreibung"
  }, [_vm._m(3), _vm._m(4), _c('div', {
    staticClass: "bundle-image"
  }, [_c('div', {
    staticClass: "bundle-part"
  }, [_vm._m(5), _c('div', {
    staticClass: "bundle-name"
  }, [_vm._v("Anamnese")]), _c('div', {
    staticClass: "bundle-price"
  }, [_vm._v(_vm._s(_vm._f("euro")(_vm.priceAnamneseDiamond)))]), _c('div', {
    staticClass: "bundle-sign"
  }, [_vm._v("+")])]), _c('div', {
    staticClass: "bundle-part"
  }, [_vm._m(6), _c('div', {
    staticClass: "bundle-name"
  }, [_vm._v("Factoring")]), _c('div', {
    staticClass: "bundle-price"
  }, [_vm._v(_vm._s(_vm._f("euro")(_vm.priceFactoring)))]), _c('div', {
    staticClass: "bundle-sign"
  }, [_vm._v("+")])]), _c('div', {
    staticClass: "bundle-part"
  }, [_vm._m(7), _c('div', {
    staticClass: "bundle-name"
  }, [_vm._v("Documents")]), _c('div', {
    staticClass: "bundle-price"
  }, [_vm._v(_vm._s(_vm._f("euro")(_vm.priceDocuments)))]), _c('div', {
    staticClass: "bundle-sign"
  }, [_vm._v("=")])]), _c('div', {
    staticClass: "bundle-result"
  }, [_c('div', {
    staticClass: "bundle-name"
  }, [_vm._v("Bundle")]), _c('div', {
    staticClass: "bundle-not-price"
  }, [_vm._v(_vm._s(_vm._f("euro")(_vm.priceAnamneseDiamond + _vm.priceFactoring + _vm.priceDocuments)))]), _c('div', {
    staticClass: "bundle-price"
  }, [_vm._v(_vm._s(_vm._f("euro")(_vm.priceFactoringAndAnamnese)))])])])])]), _vm._m(8)]), _c('div', {
    staticClass: "choice-wrapper"
  }, [_c('div', {
    staticClass: "choice",
    attrs: {
      "data-testid": "highlights-anamnesediamond"
    },
    on: {
      "click": function ($event) {
        return _vm.selectFeature(_vm.R4CAPP.ANAMNESE, _vm.FEATURES.ANAMNESE_DIAMOND, true);
      }
    }
  }, [_vm._m(9), _c('div', {
    staticClass: "beschreibung"
  }, [_vm._m(10), _vm._m(11), _c('div', {
    staticClass: "bundle-image"
  }, [_c('div', {
    staticClass: "bundle-part"
  }, [_vm._m(12), _c('div', {
    staticClass: "bundle-name"
  }, [_vm._v("Anamnese")]), _c('div', {
    staticClass: "bundle-price"
  }, [_vm._v(_vm._s(_vm._f("euro")(_vm.priceAnamneseDiamond)))]), _c('div', {
    staticClass: "bundle-sign"
  }, [_vm._v("+")])]), _c('div', {
    staticClass: "bundle-part"
  }, [_vm._m(13), _c('div', {
    staticClass: "bundle-name"
  }, [_vm._v("Documents")]), _c('div', {
    staticClass: "bundle-price"
  }, [_vm._v(_vm._s(_vm._f("euro")(_vm.priceDocuments)))]), _c('div', {
    staticClass: "bundle-sign"
  }, [_vm._v("=")])]), _c('div', {
    staticClass: "bundle-result"
  }, [_c('div', {
    staticClass: "bundle-name"
  }, [_vm._v("Bundle")]), _c('div', {
    staticClass: "bundle-not-price"
  }, [_vm._v(_vm._s(_vm._f("euro")(_vm.priceAnamneseDiamond + _vm.priceDocuments)))]), _c('div', {
    staticClass: "bundle-price"
  }, [_vm._v(_vm._s(_vm._f("euro")(_vm.priceAnamneseDiamond)))])])])])]), _vm._m(14)])]), _c(VBtn, {
    staticClass: "mt-4 float-right",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.nextTab();
      }
    }
  }, [_vm._v("Weiter")]), _vm.proceedToRechenzentren ? _c(VBtn, {
    staticClass: "mr-2 mt-4 float-right",
    attrs: {
      "disabled": !_vm.somethingMeaningfulWasSelected,
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.next();
      }
    }
  }, [_vm._v("Direkt zur Rechenzentrumsauswahl")]) : _vm._e()], 1) : _vm.selectedApp ? _c('div', {
    staticClass: "app"
  }, [_c('div', {
    staticClass: "marketing-text mb-4"
  }, [_vm._v(_vm._s(_vm.selectedApp.beschreibung))]), _vm.selectedApp.hint ? _c(VAlert, {
    staticClass: "mt-4",
    attrs: {
      "prominent": "",
      "outlined": "",
      "border": "left",
      "text": "",
      "type": "info"
    }
  }, [_vm._v(_vm._s(_vm.selectedApp.hint))]) : _vm._e(), _vm.selectedApp.rendertyp !== "TABLE" ? _c('div', {
    staticClass: "pakete-neu",
    attrs: {
      "id": "choices-" + _vm.selectedApp.id
    }
  }, _vm._l(_vm.selectedApp.choices, function (choice) {
    return _c('div', {
      staticClass: "choice-wrapper",
      class: {
        selected: _vm.isFeatureSelected(_vm.selectedApp.id, choice.feature),
        inactive: !_vm.isFeatureSelected(_vm.selectedApp.id, choice.feature)
      },
      on: {
        "click": function ($event) {
          return _vm.selectFeature(_vm.selectedApp.id, choice.feature);
        }
      }
    }, [_c('div', {
      staticClass: "choice",
      attrs: {
        "data-testid": "feature-" + choice.feature
      }
    }, [_c('h3', {
      staticClass: "choiceHeading"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(choice.name))]), choice.nameSpecial ? _c('div', {
      staticClass: "extra"
    }, [_vm._v(_vm._s(choice.nameSpecial))]) : _vm._e()]), _c('div', {
      staticClass: "beschreibung"
    }, [_c('p', [_vm._v(_vm._s(choice.beschreibung))])]), _c('div', {
      staticClass: "preis"
    }, [_c('span', {
      staticClass: "preis-pro-monat"
    }, [_vm._v("Monatl.")]), _c('span', {
      class: {
        linethrough: _vm.isFeatureAlreadySelectedElseWhere(_vm.selectedApp.id, choice.feature)
      }
    }, [_vm._v(_vm._s(_vm._f("euro")(choice.price)))])]), choice.itemPrice > 0 ? _c('div', {
      staticClass: "preis-item"
    }, [_vm._v(_vm._s(_vm._f("euro")(choice.itemPrice, true)) + " / " + _vm._s(choice.itemName))]) : _vm._e(), choice.itemsIncluded ? _c('div', {
      staticClass: "icluded-items"
    }, [_vm._v(_vm._s(choice.itemNamePlural) + " enthalten: " + _vm._s(choice.itemsIncluded))]) : _vm._e(), _c('div', {
      staticClass: "preis-hint"
    }, [_vm._v("Preise zzgl. MwSt.")]), _c('div', {
      staticClass: "feature-list"
    }, _vm._l(choice.highlights, function (features, group) {
      return _c('div', {
        staticClass: "feature-group"
      }, [group !== "none" ? _c('div', {
        staticClass: "feature-group-name"
      }, [_vm._v(_vm._s(group))]) : _vm._e(), _vm._l(features, function (feature) {
        return _c('div', {
          staticClass: "feature"
        }, [_c(VIcon, {
          staticClass: "mr-1",
          attrs: {
            "color": "success",
            "small": ""
          }
        }, [_vm._v("far fa-check")]), _c('span', [_vm._v(_vm._s(feature.txt))]), feature.explanation ? _c('div', {
          staticClass: "footnote-card"
        }, [_vm._v(_vm._s(feature.explanation?.txt))]) : _vm._e()], 1);
      })], 2);
    }), 0)]), _c('div', {
      staticClass: "choose",
      class: {
        inactive: _vm.isFeatureSelected(_vm.selectedApp.id, choice.feature)
      }
    }, [_vm.isFeatureSelected(_vm.selectedApp.id, choice.feature) ? _c('span', [_vm._v("Ausgewählt")]) : _c('span', [_vm._v("Auswählen")])])]);
  }), 0) : _vm._e(), _vm.selectedApp.rendertyp !== "CARD" ? _c('div', {
    staticClass: "feature-compare"
  }, [_c('div', {
    staticClass: "feature-compare-header"
  }, [_vm._v("Pakete")]), _c(VSimpleTable, {
    attrs: {
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', [_c('tr', [_c('th'), _vm._l(_vm.selectedFeatureMatrix.columns, function (c) {
          return _c('th', {
            staticClass: "unselected-cell-top",
            class: {
              "selected-cell-top": _vm.isFeatureSelected(_vm.selectedApp.id, c.feature)
            },
            style: {
              "width": 100 / (_vm.selectedFeatureMatrix.columns.length + 2) + "%"
            }
          }, [_c('div', {
            staticClass: "feature-head",
            on: {
              "click": function ($event) {
                return _vm.selectFeature(_vm.selectedApp.id, c.feature);
              }
            }
          }, [_c('span', [_vm._v(_vm._s(c.name))])])]);
        })], 2)]), _c('tbody', [_vm._l(_vm.selectedFeatureMatrix.matrix, function (line, idx) {
          return [line.typ === "header" ? _c('tr', {
            class: {
              "feature-header": !line.price,
              "price-header": line.price
            }
          }, [_c('td', [_vm._v(" " + _vm._s(line.txt))]), _vm._l(_vm.selectedFeatureMatrix.columns, function (c) {
            return _c('td', {
              staticClass: "unselected-cell",
              class: {
                "selected-cell": _vm.isFeatureSelected(_vm.selectedApp.id, line["f_" + c.name])
              }
            }, [_c('div', {
              staticClass: "feature-head-empty",
              on: {
                "click": function ($event) {
                  return _vm.selectFeature(_vm.selectedApp.id, c.feature);
                }
              }
            })]);
          })], 2) : line.typ === "feature" ? _c('tr', {
            staticClass: "feature-line",
            class: {
              "feature-line-even": !line.price && idx % 2 === 0,
              "price-line-even": line.price && idx % 2 === 0
            }
          }, [_c('td', [_c('div', {
            staticClass: "feature-name"
          }, [_c('div', {
            staticClass: "txt"
          }, [_vm._v(_vm._s(line.txt))]), line.explanation ? _c('div', {
            staticClass: "footnote"
          }, [_vm._v(_vm._s(line.explanation.txt))]) : _vm._e(), line.i ? _c(VTooltip, {
            staticClass: "help",
            attrs: {
              "right": ""
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function ({
                on,
                attrs
              }) {
                return [_c(VIcon, _vm._g(_vm._b({
                  staticClass: "mr-1",
                  attrs: {
                    "small": ""
                  }
                }, 'v-icon', attrs, false), on), [_vm._v("fas fa-circle-info")])];
              }
            }], null, true)
          }, [_c('span', [_vm._v(_vm._s(line.i))])]) : _vm._e()], 1)]), _vm._l(_vm.selectedFeatureMatrix.columns, function (c) {
            return _c('td', {
              staticClass: "unselected-cell",
              class: {
                "selected-cell": !line.last && _vm.isFeatureSelected(_vm.selectedApp.id, line["f_" + c.name]),
                "selected-cell-bottom": line.last && _vm.isFeatureSelected(_vm.selectedApp.id, line["f_" + c.name]),
                "big-cell": line.big
              }
            }, [_c('div', {
              staticClass: "feature-on",
              on: {
                "click": function ($event) {
                  return _vm.selectFeature(_vm.selectedApp.id, c.feature);
                }
              }
            }, [line[c.name] ? _c(VIcon, {
              attrs: {
                "small": ""
              }
            }, [_vm._v("far fa-check")]) : _vm._e()], 1)]);
          })], 2) : line.typ === "licence" ? _c('tr', {
            staticClass: "licence-line",
            class: {
              "feature-line-even": idx % 2 === 0
            }
          }, [_c('td', [_c('div', {
            staticClass: "feature-name"
          }, [_c('div', {
            staticClass: "txt"
          }, [_vm._v(_vm._s(line.txt))])])]), _vm._l(_vm.selectedFeatureMatrix.columns, function (c) {
            return _c('td', {
              staticClass: "unselected-cell",
              class: {
                "selected-cell": _vm.isFeatureSelected(_vm.selectedApp.id, c.feature)
              }
            }, [_c('div', {
              staticClass: "feature-txt",
              on: {
                "click": function ($event) {
                  return _vm.selectFeature(_vm.selectedApp.id, c.feature);
                }
              }
            }, [_c('span', {
              domProps: {
                "innerHTML": _vm._s(line[c.name])
              }
            })])]);
          })], 2) : _c('tr', {
            staticClass: "price-line",
            class: {
              "price-line-even": idx % 2 === 0
            }
          }, [_c('td', [_c('div', {
            staticClass: "feature-name"
          }, [_c('div', {
            staticClass: "txt"
          }, [_vm._v(_vm._s(line.txt))])])]), _vm._l(_vm.selectedFeatureMatrix.columns, function (c) {
            return _c('td', {
              staticClass: "unselected-cell",
              class: {
                "selected-cell": !c.last && _vm.isFeatureSelected(_vm.selectedApp.id, line["f_" + c.name]),
                "selected-cell-bottom": c.last && _vm.isFeatureSelected(_vm.selectedApp.id, line["f_" + c.name]),
                "big-cell": line.big
              }
            }, [_c('div', {
              staticClass: "feature-txt",
              on: {
                "click": function ($event) {
                  return _vm.selectFeature(_vm.selectedApp.id, c.feature);
                }
              }
            }, [_vm._v(_vm._s(line[c.name]))])]);
          })], 2)];
        })], 2)];
      },
      proxy: true
    }], null, false, 1612047879)
  }), _c('div', {
    staticClass: "mwst-hint"
  }, [_vm._v("Alle Preise zzgl. MwSt")])], 1) : _vm._e(), _c('div', {
    staticClass: "choice-wrapper",
    class: {
      selected: _vm.selectedFeatures[_vm.selectedApp.id] === _vm.FEATURES.NONE,
      inactive: _vm.selectedFeatures[_vm.selectedApp.id] !== _vm.FEATURES.NONE
    }
  }, [_c('div', {
    staticClass: "cancel-choice",
    attrs: {
      "data-testid": "cancel-" + _vm.selectedApp.id
    },
    on: {
      "click": function ($event) {
        return _vm.selectFeature(_vm.selectedApp.id, _vm.FEATURES.NONE);
      }
    }
  }, [_c('div', {
    staticClass: "beschreibung"
  }, [_vm.selectedFeatures[_vm.selectedApp.id] === _vm.FEATURES.NONE ? _c('span', [_vm._v("Nicht ausgewählt")]) : _c('span', [_vm._v(_vm._s(_vm.selectedApp.name) + " abwählen")])])]), _c('div', {
    staticClass: "choose",
    class: {
      inactive: _vm.selectedFeatures[_vm.selectedApp.id] === _vm.FEATURES.NONE
    }
  }, [_c('span', [_vm._v("Du möchtest " + _vm._s(_vm.selectedApp.name) + " jetzt nicht buchen? Dann kannst du das jederzeit nachträglich tun.")])])]), !_vm.somethingMeaningfulWasSelected && _vm.currentTab === _vm.lastTab ? _c(VAlert, {
    staticClass: "change-alert mt-4",
    attrs: {
      "prominent": "",
      "outlined": "",
      "border": "left",
      "text": "",
      "type": "warning"
    }
  }, [_vm.editR4cApps ? _c('span', [_vm._v("Bitte ändere erst etwas")]) : _c('span', [_vm._v("Bitte wähle erst etwas aus")])]) : _vm._e(), _vm.currentTab !== _vm.lastTab ? [_c(VBtn, {
    staticClass: "mt-4 float-right",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.nextTab();
      }
    }
  }, [_vm._v("Weiter")])] : [_c(VBtn, {
    staticClass: "mt-4 float-right",
    attrs: {
      "disabled": !_vm.somethingMeaningfulWasSelected,
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.next();
      }
    }
  }, [_vm._v("Weiter")])]], 2) : _vm._e()], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('h3', [_c('span', [_vm._v("rose4charly - r4c")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "marketing-text mt-4 mb-4"
  }, [_c('p', [_vm._v("Unsere rose4charly Apps kannst nach deinen individuellen Anforderungen und Bedürfnissen freischalten und verwenden. Lass dich von der Zeitersparnis und Arbeitserleichterung begeistern und probiere es einfach selber aus. Sollte eine App einmal nicht deinen Vorstellungen entsprechen, kannst du die Nutzung jeweils zum Ende des Monats einfach wieder beenden.")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "ribbon"
  }, [_c('div', {
    staticClass: "ribbon-text ribbon-text-green"
  }, [_vm._v("Premium")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "beschreibung-header"
  }, [_c('h3', [_vm._v("Digitale Praxis")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "beschreibung-text"
  }, [_c('p', [_vm._v("Du bist Digitalisierungs-Pro oder möchtest es werden, dann bekommst du mit diesem Paket "), _c('b', [_vm._v("alle")]), _vm._v(" unsere Apps und kannst direkt loslegen.")]), _c('p', [_vm._v("In diesem Paket sind enthalten:")]), _c('ul', [_c('li', [_vm._v("Factoring")]), _c('li', [_vm._v("Documents")]), _c('li', [_vm._v("Anamnese-Fragebögen und Dokumente")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "bundle-icon"
  }, [_c('i', {
    staticClass: "fa-duotone fa-3x fa-clipboard-medical"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "bundle-icon"
  }, [_c('div', {
    staticClass: "fa-stack fa-lg"
  }, [_c('i', {
    staticClass: "fa-duotone fa-shield fa-stack-2x"
  }), _c('i', {
    staticClass: "fa fa-euro-sign fa-stack-1x"
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "bundle-icon"
  }, [_c('i', {
    staticClass: "fa-duotone fa-3x fa-file-contract"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "choose"
  }, [_c('span', [_vm._v("Auswählen")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "ribbon"
  }, [_c('div', {
    staticClass: "ribbon-text ribbon-text-green"
  }, [_vm._v("NEU!!")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "beschreibung-header"
  }, [_c('h3', [_vm._v("Anamnese - Fragebögen und Dokumente")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "beschreibung-text"
  }, [_c('p', [_vm._v("Ganz "), _c('b', [_vm._v("neu")]), _vm._v(" ist unsere "), _c('b', [_vm._v("Anamnese App")]), _vm._v(". Mit rose4charly Anamnese kannst du deine Fragebögen und Dokumente erstellen und verwalten. Voll digitaler Praxis-Ablauf. Kein Papier mehr. "), _c('b', [_vm._v("So viel du willst!")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "bundle-icon"
  }, [_c('i', {
    staticClass: "fa-duotone fa-3x fa-clipboard-medical"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "bundle-icon"
  }, [_c('i', {
    staticClass: "fa-duotone fa-3x fa-file-contract"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "choose"
  }, [_c('span', [_vm._v("Auswählen")])]);
}];
export { render, staticRenderFns };