import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VRow, {
    attrs: {
      "justify": "center"
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "10",
      "md": "8",
      "lg": "6"
    }
  }, [_c(VCard, [_c(VCardText, [_c(VForm, {
    model: {
      value: _vm.formValid,
      callback: function ($$v) {
        _vm.formValid = $$v;
      },
      expression: "formValid"
    }
  }, [_c('h3', {
    staticClass: "mb-4"
  }, [_vm._v("Zahlungsdaten"), _c(VBtn, {
    staticClass: "ml-2 pt-0",
    attrs: {
      "color": "info",
      "small": "",
      "data-testid": "takeAuftraggeber"
    },
    on: {
      "click": _vm.takeAuftraggeber
    }
  }, [_vm._v("Auftraggeber übernehmen")])], 1), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Kontoinhaber",
      "required": ""
    },
    model: {
      value: _vm.zahlungsdaten.kontoinhaber,
      callback: function ($$v) {
        _vm.$set(_vm.zahlungsdaten, "kontoinhaber", $$v);
      },
      expression: "zahlungsdaten.kontoinhaber"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.email],
      "label": "Kontoinhaber / Buchhaltung E-Mail",
      "required": "",
      "persistent-hint": "",
      "hint": "An diese E-Mail werden Abbuchungsankündigungen und Rechnungen gesendet."
    },
    model: {
      value: _vm.zahlungsdaten.kontoinhaberEmail,
      callback: function ($$v) {
        _vm.$set(_vm.zahlungsdaten, "kontoinhaberEmail", $$v);
      },
      expression: "zahlungsdaten.kontoinhaberEmail"
    }
  }), _c('label', {
    attrs: {
      "for": "iban-element"
    }
  }, [_vm._v("IBAN")]), _c('div', {
    attrs: {
      "id": "iban-element"
    }
  }), _vm.isDev || _vm.isStaging ? _c('button', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: 'DE89370400440532013000',
      expression: "'DE89370400440532013000'",
      arg: "copy"
    }],
    attrs: {
      "type": "button",
      "data-testid": "copy-iban"
    }
  }, [_vm._v("test iban: DE89370400440532013000")]) : _vm._e(), _c('div', {
    attrs: {
      "id": "mandate-acceptance"
    }
  }, [_c('span', [_vm._v("Ich ermächtige (A) die rose GmbH, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich (B) weise ich mein unser Kreditinstitut an, die von der rose GmbH auf mein Konto gezogenen Lastschriften einzulösen.")])])], 1)], 1), _c(VDivider, {
    staticClass: "mt-8"
  }), _c(VCardActions, [_c('div', {
    staticClass: "flex-grow-1"
  }), _c(VBtn, {
    attrs: {
      "color": "primary",
      "disabled": !_vm.allValid,
      "loading": _vm.loading,
      "data-testid": "next"
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v("Zustimmen und weiter")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };