import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.flat-map.js";
import "core-js/modules/esnext.iterator.map.js";
import { filter, isNil, chain, map, isEmpty, intersection } from 'lodash';
import { defineComponent } from 'vue';
import { hasApp, hasAppFeature, onboardingStore } from '@/state/onboardingStore';
import { paketeR4c, featureMatrix, paketeR4cMap, findAppChoice, isFeatureAlreadySelectedElseWhere, findAppChoiceByFeature } from '@/state/pakete';
import { isProd, isStaging, isDev } from '../../../common-ui';
import { FEATURES, R4CAPP } from '../../../types';
import { StepId } from '@/state/tasks';
export default defineComponent({
  components: {},
  props: ['viewParams'],
  data: () => ({
    selectedFeatures: {
      [R4CAPP.FACTORING]: FEATURES.NONE,
      [R4CAPP.DOCUMENTS]: FEATURES.NONE,
      [R4CAPP.ANAMNESE]: FEATURES.NONE
    },
    onboardingStore,
    isDev,
    isStaging,
    isProd,
    FEATURES,
    R4CAPP,
    currentTabIdx: 0,
    lastTab: R4CAPP.DOCUMENTS,
    proceedToRechenzentren: false
  }),
  computed: {
    paketeR4cToShow() {
      return filter(paketeR4c, app => !isProd || app.live);
    },
    tabs() {
      const tabs = [];
      if (!this.editR4cApps) {
        tabs.push('highlights');
      }
      for (const app of this.paketeR4cToShow) {
        tabs.push(app.id);
      }
      return tabs;
    },
    currentTab() {
      return this.tabs[this.currentTabIdx];
    },
    selectedApp() {
      return paketeR4cMap[this.currentTab];
    },
    selectedFeatureMatrix() {
      if (this.currentTab !== 'highlights') {
        return featureMatrix(paketeR4cMap[this.currentTab]);
      }
      return undefined;
    },
    hasFactoringAndAnamnese() {
      return this.selectedFeatures[R4CAPP.ANAMNESE] === FEATURES.ANAMNESE_DIAMOND && this.selectedFeatures[R4CAPP.FACTORING] === FEATURES.FACTORINGEWE;
    },
    priceFactoringAndAnamnese() {
      const factoringEweChoice = findAppChoice(R4CAPP.FACTORING, FEATURES.FACTORINGEWE) || {
        price: 0
      };
      const anamneseChoice = findAppChoice(R4CAPP.ANAMNESE, FEATURES.ANAMNESE_DIAMOND) || {
        price: 0
      };
      return (factoringEweChoice === null || factoringEweChoice === void 0 ? void 0 : factoringEweChoice.price) + (anamneseChoice === null || anamneseChoice === void 0 ? void 0 : anamneseChoice.price);
    },
    priceAnamneseDiamond() {
      const anamneseChoice = findAppChoice(R4CAPP.ANAMNESE, FEATURES.ANAMNESE_DIAMOND) || {
        price: 0
      };
      return anamneseChoice === null || anamneseChoice === void 0 ? void 0 : anamneseChoice.price;
    },
    priceFactoring() {
      const factoringChoice = findAppChoice(R4CAPP.FACTORING, FEATURES.FACTORINGEWE) || {
        price: 0
      };
      return factoringChoice === null || factoringChoice === void 0 ? void 0 : factoringChoice.price;
    },
    priceDocuments() {
      const docsChoice = findAppChoice(R4CAPP.DOCUMENTS, FEATURES.DOCUMENTS_FLAT) || {
        price: 0
      };
      return docsChoice === null || docsChoice === void 0 ? void 0 : docsChoice.price;
    },
    editR4cApps() {
      var _a;
      return !!((_a = this.viewParams) === null || _a === void 0 ? void 0 : _a.editR4cApps);
    },
    somethingMeaningfulWasSelected() {
      const res = !chain(this.selectedFeatures).values().filter(f => f !== FEATURES.NONE).isEmpty().value() || this.editR4cApps;
      console.log('somethingMeaningfulWasSelected', this.selectedFeatures, res);
      return res;
    }
  },
  mounted() {
    console.log('query', this.$route.query.d);
    console.log('viewParams', this.viewParams);
    if (this.editR4cApps) {
      // set current packages
      if (onboardingStore.getters.factoringActive) {
        this.selectedFeatures[R4CAPP.FACTORING] = onboardingStore.getters.factoringBasicActive ? FEATURES.FACTORING : FEATURES.FACTORINGEWE;
        // if we already have rz, then do not enable rz step
        onboardingStore.commit.setSkipStepByStepId({
          stepId: StepId.r4cRechenzentren,
          skip: true
        });
      }
      if (onboardingStore.getters.documentsActive) {
        if (onboardingStore.getters.documentsSmallActive) {
          this.selectedFeatures[R4CAPP.DOCUMENTS] = FEATURES.DOCUMENTS_SMALL;
        }
        if (onboardingStore.getters.documentsBigActive) {
          this.selectedFeatures[R4CAPP.DOCUMENTS] = FEATURES.DOCUMENTS_BIG;
        }
        if (onboardingStore.getters.documentsFlatActive) {
          this.selectedFeatures[R4CAPP.DOCUMENTS] = FEATURES.DOCUMENTS_FLAT;
        }
      }
    }
    this.setSkipStep();
    // this view cannot be invalid
    this.$emit('viewValidUpdate', true);
  },
  methods: {
    selectFeature(appId, feature, reset = false) {
      this.proceedToRechenzentren = false;
      if (reset) {
        for (const appId in this.selectedFeatures) {
          if (Object.prototype.hasOwnProperty.call(this.selectedFeatures, appId)) {
            this.selectedFeatures[appId] = FEATURES.NONE;
          }
        }
      }
      this.selectedFeatures[appId] = feature;
      this.setSkipStep();
    },
    selectFactoringAndAnamnese() {
      this.selectedFeatures[R4CAPP.FACTORING] = FEATURES.FACTORINGEWE;
      this.selectedFeatures[R4CAPP.ANAMNESE] = FEATURES.ANAMNESE_DIAMOND;
      this.proceedToRechenzentren = true;
      console.log('selectFactoringAndAnamnese', this.selectedFeatures, this.proceedToRechenzentren);
      this.setSkipStep();
    },
    hasApp(app) {
      return hasApp(app);
    },
    hasAppFeature(app, feature) {
      return hasAppFeature(app, feature);
    },
    hasSelectedFeatures(appId) {
      var _a;
      // check if other include features from this app
      const thisAppsFeatures = map((_a = paketeR4cMap[appId]) === null || _a === void 0 ? void 0 : _a.choices, c => c.feature);
      const otherApps = chain(paketeR4cMap).keys().filter(k => k !== appId).value();
      const otherAppSelectedFeatures = chain(otherApps).map(oa => this.selectedFeatures[oa]).compact().filter(f => f !== FEATURES.NONE).value();
      const otherAppCheck = chain(otherAppSelectedFeatures).flatMap(f => findAppChoiceByFeature(f)).compact().flatMap(c => c.includesOtherFeatures).compact().value();
      // console.log(
      //   'otherAppSelectedFeatures',
      //   appId,
      //   thisAppsFeatures,
      //   otherApps,
      //   otherAppSelectedFeatures,
      //   otherAppCheck,
      // );
      if (!isEmpty(intersection(thisAppsFeatures, otherAppCheck))) {
        return true;
      }
      return this.selectedFeatures[appId] !== FEATURES.NONE;
    },
    isFeatureSelected(appId, feature) {
      return this.selectedFeatures[appId] === feature || this.isFeatureAlreadySelectedElseWhere(appId, feature);
    },
    isFeatureAlreadySelectedElseWhere(appId, feature) {
      return isFeatureAlreadySelectedElseWhere(appId, feature, this.selectedFeatures).included;
    },
    setSkipStep() {
      const skip = this.selectedFeatures[R4CAPP.FACTORING] === FEATURES.NONE || isNil(this.selectedFeatures[R4CAPP.FACTORING]);
      if (!onboardingStore.getters.factoringActive) {
        onboardingStore.commit.setSkipStepByStepId({
          stepId: StepId.r4cRechenzentren,
          skip
        });
      }
    },
    persistData() {
      onboardingStore.commit.setFactoringFeature(this.selectedFeatures[R4CAPP.FACTORING]);
      onboardingStore.commit.setDocumentFeature(this.selectedFeatures[R4CAPP.DOCUMENTS]);
      onboardingStore.commit.setAnamneseFeature(this.selectedFeatures[R4CAPP.ANAMNESE]);
    },
    goto(id) {
      const e = document === null || document === void 0 ? void 0 : document.getElementById(id);
      console.log('goto', document, e);
      e === null || e === void 0 ? void 0 : e.scrollIntoView();
    },
    nextTab() {
      this.currentTabIdx++;
    },
    next() {
      this.$emit('next');
      // since this was the first interaction add unload handler here
      if (isProd || isStaging) {
        window.onbeforeunload = () => 'Sind sie sicher, dass Sie den Vorgang abbrechen wollen?';
      }
    }
  }
});