import { defineComponent } from 'vue';
import * as _ from 'lodash';
import { onboardingStore } from '@/state/onboardingStore';
import { r4cAVVHtml, metricsAVVHtml } from '../../../base';
export default defineComponent({
  components: {},
  computed: {
    avvHtml() {
      var _a, _b, _c, _d, _e;
      _.templateSettings.interpolate = /{{([\s\S]+?)}}/g; // enable {{ variable }} syntax
      const compiled = onboardingStore.state.isR4cOnboarding ? _.template(r4cAVVHtml) : _.template(metricsAVVHtml);
      const pd = onboardingStore.state && onboardingStore.state.praxisDaten || {
        auftraggeber: {}
      };
      const ap = onboardingStore.state && onboardingStore.state.technischerAnprechpartner || {};
      const admin = onboardingStore.state && onboardingStore.state.adminBenutzer || {};
      return compiled({
        praxisname: `${(_a = pd.auftraggeber) === null || _a === void 0 ? void 0 : _a.name} ${(_b = pd.auftraggeber) === null || _b === void 0 ? void 0 : _b.vorname}` || 'Die Praxis',
        praxisstrasse: ((_c = pd.auftraggeber) === null || _c === void 0 ? void 0 : _c.straße) || 'Die Strasse',
        praxisplz: ((_d = pd.auftraggeber) === null || _d === void 0 ? void 0 : _d.plz) || 'Die PLZ',
        praxisort: ((_e = pd.auftraggeber) === null || _e === void 0 ? void 0 : _e.stadt) || 'Die Stadt',
        ansprechpartnervorname: ap.vorname || 'Der ansprechpartnervorname',
        ansprechpartnernachname: ap.name || 'Der ansprechpartnernachname',
        adminvorname: admin.vorname || 'Der adminvorname',
        adminnachname: admin.name || 'Der adminnachname'
      });
    }
  }
});