import { onboardingStore } from '@/state/onboardingStore';
import { defineComponent } from 'vue';
export default defineComponent({
  components: {},
  data: () => ({
    isR4cOnboarding: onboardingStore.state.isR4cOnboarding
  }),
  computed: {},
  methods: {
    /**
     * This gets called every time when this view gets active
     */
    async refreshHook() {
      // clear unload handler if exist
      window.onbeforeunload = null;
    }
  }
});