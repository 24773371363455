import { defineComponent } from 'vue';
import { onboardingStore } from '@/state/onboardingStore';
import { getStripeElements, getStripe } from '../services/stripe';
import { createPaymentIntent } from '../services/data';
import { isDev, isStaging, rules } from '../../../common-ui';
export default defineComponent({
  components: {},
  data: () => ({
    loading: false,
    zahlungsdaten: {
      kontoinhaber: '',
      kontoinhaberEmail: ''
    },
    formValid: false,
    ibanValid: false,
    rules: rules,
    ibanElement: null,
    client_secret: '',
    payment_method: '',
    isDev,
    isStaging
  }),
  computed: {
    allValid() {
      return this.formValid && (this.ibanValid || isDev || isStaging);
    },
    viewComplete() {
      return this.allValid && !!this.payment_method;
    }
  },
  watch: {
    viewComplete() {
      this.$emit('viewValidUpdate', this.viewComplete);
    }
  },
  async mounted() {
    const style = {
      base: {
        fontSize: '16px',
        color: '#32325d'
      }
    };
    const options = {
      style: style,
      supportedCountries: ['SEPA'],
      // If you know the country of the customer, you can optionally pass it to
      // the Element as placeholderCountry. The example IBAN that is being used
      // as placeholder reflects the IBAN format of that country.
      placeholderCountry: 'DE'
    };
    const stripeElements = await getStripeElements();
    const ibanElement = stripeElements.create('iban', options);
    ibanElement.mount('#iban-element');
    ibanElement.on('change', event => {
      this.ibanValid = event.complete;
      // clear payment method on new iban
      this.payment_method = '';
    });
    this.ibanElement = ibanElement;
  },
  methods: {
    /**
     * This gets called every time when this view gets active
     */
    async refreshHook() {
      const {
        client_secret
      } = await createPaymentIntent();
      console.log('paymentIntentId', client_secret);
      this.client_secret = client_secret;
    },
    takeAuftraggeber() {
      if (onboardingStore.state.praxisDaten && onboardingStore.state.praxisDaten.auftraggeber) {
        this.zahlungsdaten = {
          kontoinhaber: `${onboardingStore.state.praxisDaten.auftraggeber.vorname} ${onboardingStore.state.praxisDaten.auftraggeber.name}`,
          kontoinhaberEmail: onboardingStore.state.praxisDaten.auftraggeber.email || ''
        };
      }
    },
    persistData() {
      onboardingStore.commit.setZahlungsdaten({
        kontoinhaber: this.zahlungsdaten.kontoinhaber,
        kontoinhaberEmail: this.zahlungsdaten.kontoinhaberEmail,
        payment_method_id: this.payment_method
      });
    },
    async next() {
      this.loading = true;
      // example iban DE89370400440532013000
      const stripe = await getStripe();
      // handle stripe
      try {
        const res = await stripe.confirmSepaDebitSetup(this.client_secret, {
          payment_method: {
            sepa_debit: this.ibanElement,
            billing_details: {
              name: this.zahlungsdaten.kontoinhaber,
              email: this.zahlungsdaten.kontoinhaberEmail
            }
          }
        });
        this.loading = false;
        console.log('res', res);
        if (res.error) {
          throw res.error;
        }
        if (!res.setupIntent) {
          throw new Error('stripe: no setupIntent');
        }
        const payment_method = res.setupIntent.payment_method;
        if (!payment_method) {
          throw new Error('stripe: no payment method');
        }
        this.payment_method = payment_method;
        this.$emit('next');
      } catch (error) {
        const errorMessage = 'Fehler: ' + error.message || 'Fehler, bitte versuchen Sie es später erneut';
        this.loading = false;
        alert(errorMessage);
        console.log('ERROR');
        console.error(error);
      }
    }
  }
});