import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.some.js";
import "core-js/modules/web.dom-exception.stack.js";
import { defineComponent } from 'vue';
import * as _ from 'lodash';
import { rules, tzFilter, isDev, isStaging } from '../../../common-ui';
import { sleep, dzrOrgUnits } from '../../../base';
import { rzkeys } from '../../../types';
import { loadBFSCertificate, validateCertificatePassword, validateZaCredentials, validateDzrCredentials, validateHealthCredentials, validatePvsReissCredentials, validateArcCredentials, validateTeamfaktorCredentials, getPvsDentalRegistrationUrl, validatePvsDentalCredentials, validateCareCapitalCredentials, validateMediservCredentials, validateZABCredentials, validateNellyCredentials, validateFabiusCredentials, validateGutschein, factoringApi } from '../services/data';
import { onboardingStore } from '@/state/onboardingStore';
import GutscheinInfo from './GutscheinInfo.vue';
import * as aesjs from 'aes-js';
import { difference, first, map, split } from 'lodash';
function _base64ToBytes(base64) {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const array = [];
  for (let i = 0; i < len; i++) {
    array[i] = binary_string.charCodeAt(i);
  }
  return array;
}
const credentialsKeyBfs = 'opvcHaKksGkqeUATrzEaaNcAfWF6JUHH';
// sample: z3GW2KzrU6MGQHr4z9Bt9S8fLJdRz0brnDUaPe8B7MAF68WAbJPoH79FlxvFjaPGqwtIYsRoNg7rJfYZNseuuEDa+W0uP81pdovQdk8tpEvo8eoJYEzkvOiHmqRITaGx2EvwfwhHQswACzIQnBajZw==
function decodeBfsCredentials(credentialsEncoded, source) {
  let decryptedText;
  try {
    const trimmed = _.trim(credentialsEncoded);
    const keyBytes = aesjs.utils.utf8.toBytes(credentialsKeyBfs);
    const aesEcb = new aesjs.ModeOfOperation.ecb(keyBytes);
    const encryptedBytes = _base64ToBytes(trimmed);
    const decryptedBytes = aesEcb.decrypt(encryptedBytes);
    decryptedText = _.trim(aesjs.utils.utf8.fromBytes(decryptedBytes));
    // remove some trailing chars
    decryptedText = decryptedText.substring(0, decryptedText.lastIndexOf('}') + 1);
  } catch (err) {
    throw new Error(`Die Daten konnten nicht entschlüsselt werden`);
  }
  if (decryptedText) {
    console.log(`decrypted credentials (${source}): >${decryptedText}<`);
    try {
      return JSON.parse(decryptedText);
    } catch (err) {
      throw new Error(`Die entschlüsselten Daten konnten nicht gelesen werden`);
    }
  }
}
export default defineComponent({
  components: {
    GutscheinInfo
  },
  filters: {
    date: tzFilter.filters.tz
  },
  props: ['viewParams'],
  data: () => ({
    formValid: false,
    checkPending: false,
    inputCredentials: '',
    shownewhint: false,
    showc: false,
    showpw: false,
    showzpw: false,
    showzapw: false,
    showhealthpw: false,
    showdzrpw: false,
    showabzpw: false,
    showpvsreisspw: false,
    showteamfaktorpw: false,
    showpvscarecapitalpw: false,
    pvsdentalRegistrationUrl: null,
    pvsdentalWaitForRegistration: 'idle',
    gutscheinInfo: {
      bfs: undefined,
      za: undefined,
      dzr: undefined,
      abz: undefined,
      health: undefined,
      pvsreiss: undefined,
      arc: undefined,
      teamfaktor: undefined,
      pvsdental: undefined,
      carecapital: undefined,
      mediserv: undefined,
      zab: undefined,
      nelly: undefined,
      fabius: undefined,
      test: undefined
    },
    rzSelected: {
      bfs: false,
      dzr: false,
      abz: false,
      za: false,
      health: false,
      pvsreiss: false,
      arc: false,
      teamfaktor: false,
      pvsdental: false,
      carecapital: false,
      mediserv: false,
      zab: false,
      nelly: false,
      fabius: false,
      test: false
    },
    bfsDaten: {},
    zaDaten: {},
    dzrDaten: {},
    healthDaten: {},
    abzDaten: {
      org_unit: 8
    },
    pvsreissDaten: {},
    arcDaten: {},
    teamfaktorDaten: {},
    pvsdentalDaten: {},
    careCapitalDaten: {},
    mediservDaten: {},
    zabDaten: {},
    nellyDaten: {},
    fabiusDaten: {},
    testDaten: {},
    rules: rules,
    bfsCredentialsValidationStatus: null,
    bfsCertPasswordValidationStatus: null,
    zaCredentialsValidationStatus: null,
    zaCredentialsValidationError: undefined,
    healthCredentialsValidationStatus: null,
    healthCredentialsValidationError: undefined,
    dzrCredentialsValidationStatus: null,
    dzrCredentialsValidationError: undefined,
    dzrOrgUnits: dzrOrgUnits,
    abzCredentialsValidationStatus: null,
    abzCredentialsValidationError: undefined,
    pvsreissCredentialsValidationStatus: null,
    pvsreissCredentialsValidationError: undefined,
    arcCredentialsValidationStatus: null,
    arcCredentialsValidationError: undefined,
    teamfaktorCredentialsValidationStatus: null,
    teamfaktorCredentialsValidationError: undefined,
    pvsdentalCredentialsValidationStatus: null,
    pvsdentalCredentialsValidationError: undefined,
    careCapitalCredentialsValidationStatus: null,
    careCapitalCredentialsValidationError: undefined,
    mediservCredentialsValidationStatus: null,
    mediservCredentialsValidationError: undefined,
    zabCredentialsValidationStatus: null,
    zabCredentialsValidationError: undefined,
    nellyCredentialsValidationStatus: null,
    nellyCredentialsValidationError: undefined,
    fabiusCredentialsValidationStatus: null,
    fabiusCredentialsValidationError: undefined,
    activeRechenzentren: []
  }),
  computed: {
    isAdditionalRz() {
      var _a;
      return !!((_a = this.viewParams) === null || _a === void 0 ? void 0 : _a.isAdditionalRz);
    },
    allowRzRemoval() {
      var _a;
      return !!((_a = this.viewParams) === null || _a === void 0 ? void 0 : _a.allowRzRemoval);
    },
    showTestRz() {
      return isDev || isStaging;
    },
    showPvsDentalRz() {
      return isDev || isStaging;
    },
    selectedAtleastOneRz() {
      return Object.values(this.rzSelected).includes(true);
    },
    allowContinue() {
      if (!this.formValid) {
        return false;
      }
      if (this.pvsdentalWaitForRegistration === 'wait') {
        return false;
      }
      if (this.allowRzRemoval && this.activeRechenzentren.some(rz => rz.shouldDelete)) {
        return true;
      }
      return this.selectedAtleastOneRz;
    },
    canReadClipboard() {
      return false;
      // TODO: perhaps we can test it somehow...
      // let ok = false;
      // if (navigator.clipboard.readText) {
      //   try {
      //     navigator.clipboard.readText().then(x=>{
      //       ok =
      //     });
      //     console.log(`readtext OK`);
      //     ok = true;
      //   } catch (err) {
      //     console.log(`readtext NOK`);
      //   }
      // }
      // console.log('canReadClipboard', ok);
      // return ok;
    }
  },
  async mounted() {
    if (isDev) {
      this.bfsDaten = {
        benutzer: 'derBasti',
        passwort: 'Bmg4ever!!',
        zertifikattoken: 'MeGnYJ37G3',
        zertifikatpasswort: 'm9mMzc'
      };
      this.zaDaten = {
        customerNumber: '39380',
        username: 'rose_test',
        password: 'N#e47tF!'
      };
      this.healthDaten = {
        customerNumber: '1910270A',
        apiKey: 'DD95A83A30E3383675E27288936C9794A049F0AE'
      };
      this.pvsreissDaten = {
        username: 'sk@rose.dental',
        password: 'diRA!E@CdyLgsD_ftoN8ibt'
      };
      this.dzrDaten = {
        org_unit: 64,
        contract_number: 123456,
        username: 'pvs-registration-test',
        password: 'i.Olq{M<g7%Aa5\\3]uB(' // there should only be one backslash !!
      };
      this.arcDaten = {
        kunde: '5050',
        hash: 'JSHJSDHSJHSDSSADASDSAD'
      };
      this.teamfaktorDaten = {
        benutzer: '2009000',
        passwort: '12345'
      };
      this.pvsdentalDaten = {
        token: ''
      };
      this.careCapitalDaten = {
        username: '15010',
        password: 'MccAG'
      };
      this.mediservDaten = {
        kunde: '204271'
      };
      this.zabDaten = {
        username: 'TODO',
        password: 'TODO'
      };
      this.nellyDaten = {
        username: 'TODO',
        password: 'TODO',
        organization_id: 'TODO',
        customer_number: 'TODO',
        gutschein: 'nellysupercoupon'
      };
      this.fabiusDaten = {
        key: 'TODO'
      };
    }
    // load rechenzentren
    if (this.allowRzRemoval) {
      try {
        const rzs = await factoringApi.getClientRechenzentren(onboardingStore.state.roseProfile.r4chost);
        onboardingStore.commit.setActiveRechenzentren(rzs.rechenzentren);
        console.log('rzs', rzs.rechenzentren);
        const activeRechenzentren = rzs.rechenzentren.map(rz => Object.assign(Object.assign({}, rz), {
          shouldDelete: false,
          vertragsInfo: _.chain(rz.vertragsnummernodermandanten).map(v => v.name).join(', ').value()
        }));
        this.activeRechenzentren = activeRechenzentren;
      } catch (e) {
        console.error('could not fetch client rzs', e);
        alert('Aktive Rechenzentren konnten nicht gelaten werden');
      }
    }
    // if we get rz proposal from url set it
    const proposalKeys = split(this.$route.query.rz, ',');
    const activeKeys = map(onboardingStore.getters.activeRechenzentren, x => x.rzkey);
    const bestProposal = first(difference(proposalKeys, activeKeys));
    console.log('#####', proposalKeys, activeKeys, bestProposal);
    if (bestProposal) {
      this.selectRechenzentrum(bestProposal);
    }
    // if we get new url param show hint
    if (this.$route.query.new) {
      this.shownewhint = true;
    }
  },
  methods: {
    selectRechenzentrum(rzKey) {
      const isActiveSelection = this.rzSelected[rzKey];
      for (const key in this.rzSelected) {
        if (Object.prototype.hasOwnProperty.call(this.rzSelected, key)) {
          this.rzSelected[key] = false;
        }
      }
      this.rzSelected[rzKey] = !isActiveSelection;
      // special handling fpr pvs, we need to get the registration url
      if (rzKey === rzkeys.pvsdental) {
        if (onboardingStore.state.r4cSecret) {
          getPvsDentalRegistrationUrl(onboardingStore.state.r4cSecret).then(d => {
            var _a, _b, _c;
            if (d.success) {
              this.pvsdentalRegistrationUrl = ((_a = d.data) === null || _a === void 0 ? void 0 : _a.link) || null;
              if ((_b = d.data) === null || _b === void 0 ? void 0 : _b.token) {
                this.pvsdentalDaten.token = (_c = d.data) === null || _c === void 0 ? void 0 : _c.token;
              }
            } else {
              this.pvsdentalCredentialsValidationStatus = false;
              this.pvsdentalCredentialsValidationError = d.error;
            }
          }).catch(r => {
            this.pvsdentalCredentialsValidationStatus = false;
            this.pvsdentalCredentialsValidationError = r.error;
          });
        }
      }
    },
    readCredentialsFromClipboard() {
      void navigator.clipboard.readText().then(clipboardText => {
        try {
          const data = decodeBfsCredentials(clipboardText, 'clipboard');
          this.bfsDaten = {
            benutzer: data.login,
            passwort: data.loginPasswort,
            zertifikattoken: data.token,
            zertifikatpasswort: data.zertifikatPasswort
          };
        } catch (err) {
          alert(err.message);
        }
      });
    },
    readCredentialsFromInput() {
      try {
        const data = decodeBfsCredentials(this.inputCredentials, 'input');
        this.bfsDaten = {
          benutzer: data.login,
          passwort: data.loginPasswort,
          zertifikattoken: data.token,
          zertifikatpasswort: data.zertifikatPasswort
        };
      } catch (err) {
        alert(err.message);
      }
    },
    async checkGutschein(gutscheinCode, rz) {
      if (_.size(gutscheinCode) > 0) {
        try {
          const gi = await validateGutschein(gutscheinCode);
          console.log('bar', gi);
          if (gi === null || gi === void 0 ? void 0 : gi.success) {
            const giData = gi.result;
            if (giData) {
              if (giData.verbraucht) {
                this.gutscheinInfo[rz] = {
                  msg: `Gutschein ist nicht mehr gültig`,
                  type: 'error',
                  info: giData
                };
              } else {
                this.gutscheinInfo[rz] = {
                  msg: `Gutschein von ${giData.sponsor} über einen Erlass von ${giData.prozentualerkundenerlass}% gilt für ${giData.monatelaufzeit} Monate`,
                  type: 'success',
                  info: giData
                };
              }
            } else {
              this.gutscheinInfo[rz] = {
                msg: `Kein Gutschein gefunden`,
                type: 'error'
              };
            }
            console.log(gi, this.gutscheinInfo);
          } else {
            this.gutscheinInfo[rz] = {
              msg: `Fehler: ${gi.error}`,
              type: 'error'
            };
          }
        } catch (err) {
          console.log('foo', err);
        }
      } else {
        this.gutscheinInfo[rz] = undefined;
      }
      console.log('gutscheinInfo', rz, this.gutscheinInfo[rz]);
    },
    // eslint-disable-next-line complexity
    async next() {
      if (this.rzSelected.bfs) {
        await this.validateBfsInfos();
        if (!this.bfsCredentialsValidationStatus || !this.bfsCertPasswordValidationStatus) {
          return false;
        }
      }
      if (this.rzSelected.za) {
        await this.validateZaInfos();
        if (!this.zaCredentialsValidationStatus) {
          return false;
        }
      }
      if (this.rzSelected.dzr) {
        await this.validateDzrInfos();
        if (!this.dzrCredentialsValidationStatus) {
          return false;
        }
      }
      if (this.rzSelected.abz) {
        await this.validateAbzInfos();
        if (!this.abzCredentialsValidationStatus) {
          return false;
        }
      }
      if (this.rzSelected.health) {
        await this.validateHealthInfos();
        if (!this.healthCredentialsValidationStatus) {
          return false;
        }
      }
      if (this.rzSelected.pvsreiss) {
        await this.validatePvsReissInfos();
        if (!this.pvsreissCredentialsValidationStatus) {
          return false;
        }
      }
      if (this.rzSelected.arc) {
        await this.validateArcInfos();
        if (!this.arcCredentialsValidationStatus) {
          return false;
        }
      }
      if (this.rzSelected.teamfaktor) {
        await this.validateTeamfaktorInfos();
        if (!this.teamfaktorCredentialsValidationStatus) {
          return false;
        }
      }
      if (this.rzSelected.carecapital) {
        await this.validateCareCapitalInfos();
        if (!this.careCapitalCredentialsValidationStatus) {
          return false;
        }
      }
      if (this.rzSelected.mediserv) {
        await this.validateMediservInfos();
        if (!this.mediservCredentialsValidationStatus) {
          return false;
        }
      }
      if (this.rzSelected.zab) {
        await this.validateZABInfos();
        if (!this.zabCredentialsValidationStatus) {
          return false;
        }
      }
      if (this.rzSelected.nelly) {
        await this.validateNellyInfos();
        if (!this.nellyCredentialsValidationStatus) {
          return false;
        }
      }
      if (this.rzSelected.fabius) {
        await this.validateFabiusInfos();
        if (!this.fabiusCredentialsValidationStatus) {
          return false;
        }
      }
      if (this.rzSelected.pvsdental) {
        if (!this.pvsdentalCredentialsValidationStatus) {
          return false;
        }
      }
      onboardingStore.commit.setR4cRechenzentrenToDelete(this.activeRechenzentren.filter(rz => rz.shouldDelete));
      onboardingStore.commit.setR4cRechenzentrenData({
        bfs: this.rzSelected.bfs ? this.bfsDaten : null,
        za: this.rzSelected.za ? this.zaDaten : null,
        dzr: this.rzSelected.dzr ? this.dzrDaten : null,
        abz: this.rzSelected.abz ? this.abzDaten : null,
        health: this.rzSelected.health ? this.healthDaten : null,
        pvsreiss: this.rzSelected.pvsreiss ? this.pvsreissDaten : null,
        arc: this.rzSelected.arc ? this.arcDaten : null,
        teamfaktor: this.rzSelected.teamfaktor ? this.teamfaktorDaten : null,
        pvsdental: this.rzSelected.pvsdental ? this.pvsdentalDaten : null,
        carecapital: this.rzSelected.carecapital ? this.careCapitalDaten : null,
        mediserv: this.rzSelected.mediserv ? this.mediservDaten : null,
        zab: this.rzSelected.zab ? this.zabDaten : null,
        nelly: this.rzSelected.nelly ? this.nellyDaten : null,
        fabius: this.rzSelected.fabius ? this.fabiusDaten : null,
        test: this.rzSelected.test ? this.testDaten : null
      });
      onboardingStore.commit.setR4cGutscheinInfo(this.gutscheinInfo);
      this.$emit('viewValidUpdate', true);
      this.$emit('next');
    },
    async validateBfsInfos() {
      this.checkPending = true;
      try {
        // perhaps only certificate passwort was wrong. then we should not try to redownload the certificate
        if (this.bfsCredentialsValidationStatus && !this.bfsCertPasswordValidationStatus && this.bfsDaten.zertifikat) {
          try {
            const check = await validateCertificatePassword(this.bfsDaten.zertifikat, this.bfsDaten.zertifikatpasswort, this.bfsDaten.benutzer);
            this.bfsCertPasswordValidationStatus = check.ok;
          } catch (ee) {
            this.bfsCertPasswordValidationStatus = false;
          }
        } else {
          this.bfsCredentialsValidationStatus = null;
          this.bfsCertPasswordValidationStatus = null;
          if (this.bfsDaten.zertifikattoken) {
            try {
              const result = await loadBFSCertificate(this.bfsDaten.zertifikattoken, this.bfsDaten.benutzer, this.bfsDaten.passwort);
              const zertifikat = result.zertifikat;
              this.bfsDaten.zertifikat = zertifikat;
              this.bfsCredentialsValidationStatus = true;
              try {
                const check = await validateCertificatePassword(zertifikat, this.bfsDaten.zertifikatpasswort, this.bfsDaten.benutzer);
                this.bfsCertPasswordValidationStatus = check.ok;
              } catch (ee) {
                this.bfsCertPasswordValidationStatus = false;
              }
            } catch (e) {
              this.bfsCredentialsValidationStatus = false;
            }
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.checkPending = false;
      }
    },
    async validateZaInfos() {
      this.checkPending = true;
      try {
        const result = await validateZaCredentials(this.zaDaten);
        if (result) {
          this.zaCredentialsValidationStatus = result.success;
          this.zaCredentialsValidationError = result.error;
        } else {
          this.zaCredentialsValidationStatus = false;
          this.zaCredentialsValidationError = 'Fehler bei Verbindung';
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.checkPending = false;
      }
    },
    async validateDzrInfos() {
      this.checkPending = true;
      try {
        const result = await validateDzrCredentials(this.dzrDaten);
        if (result) {
          this.dzrCredentialsValidationStatus = result.success;
          this.dzrCredentialsValidationError = result.error;
        } else {
          this.dzrCredentialsValidationStatus = false;
          this.dzrCredentialsValidationError = 'Fehler bei Verbindung';
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.checkPending = false;
      }
    },
    async validateAbzInfos() {
      this.checkPending = true;
      try {
        const result = await validateDzrCredentials(this.abzDaten);
        if (result) {
          this.abzCredentialsValidationStatus = result.success;
          this.abzCredentialsValidationError = result.error;
        } else {
          this.abzCredentialsValidationStatus = false;
          this.abzCredentialsValidationError = 'Fehler bei Verbindung';
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.checkPending = false;
      }
    },
    async validateHealthInfos() {
      this.checkPending = true;
      try {
        const result = await validateHealthCredentials(this.healthDaten);
        if (result) {
          this.healthCredentialsValidationStatus = result.success;
          this.healthCredentialsValidationError = result.error;
        } else {
          this.healthCredentialsValidationStatus = false;
          this.healthCredentialsValidationError = 'Fehler bei Verbindung';
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.checkPending = false;
      }
    },
    async validatePvsReissInfos() {
      this.checkPending = true;
      try {
        const result = await validatePvsReissCredentials(this.pvsreissDaten);
        if (result) {
          this.pvsreissCredentialsValidationStatus = result.success;
          this.pvsreissCredentialsValidationError = result.error;
        } else {
          this.pvsreissCredentialsValidationStatus = false;
          this.pvsreissCredentialsValidationError = 'Fehler bei Verbindung';
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.checkPending = false;
      }
    },
    async validateArcInfos() {
      this.checkPending = true;
      try {
        const result = await validateArcCredentials(this.arcDaten);
        if (result) {
          this.arcCredentialsValidationStatus = result.success;
          this.arcCredentialsValidationError = result.error;
        } else {
          this.arcCredentialsValidationStatus = false;
          this.arcCredentialsValidationError = 'Fehler bei Verbindung';
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.checkPending = false;
      }
    },
    async validateTeamfaktorInfos() {
      this.checkPending = true;
      try {
        const result = await validateTeamfaktorCredentials(this.teamfaktorDaten);
        if (result) {
          this.teamfaktorCredentialsValidationStatus = result.success;
          this.teamfaktorCredentialsValidationError = result.error;
        } else {
          this.teamfaktorCredentialsValidationStatus = false;
          this.teamfaktorCredentialsValidationError = 'Fehler bei Verbindung';
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.checkPending = false;
      }
    },
    async validateCareCapitalInfos() {
      this.checkPending = true;
      try {
        const result = await validateCareCapitalCredentials(this.careCapitalDaten);
        if (result) {
          this.careCapitalCredentialsValidationStatus = result.success;
          this.careCapitalCredentialsValidationError = result.error;
        } else {
          this.careCapitalCredentialsValidationStatus = false;
          this.careCapitalCredentialsValidationError = 'Fehler bei Verbindung';
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.checkPending = false;
      }
    },
    async validateMediservInfos() {
      this.checkPending = true;
      try {
        const result = await validateMediservCredentials(this.mediservDaten);
        if (result) {
          this.mediservCredentialsValidationStatus = result.success;
          this.mediservCredentialsValidationError = result.error;
        } else {
          this.mediservCredentialsValidationStatus = false;
          this.mediservCredentialsValidationError = 'Fehler bei Verbindung';
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.checkPending = false;
      }
    },
    async validateZABInfos() {
      this.checkPending = true;
      try {
        const result = await validateZABCredentials(this.zabDaten);
        if (result) {
          this.zabCredentialsValidationStatus = result.success;
          this.zabCredentialsValidationError = result.error;
        } else {
          this.zabCredentialsValidationStatus = false;
          this.zabCredentialsValidationError = 'Fehler bei Verbindung';
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.checkPending = false;
      }
    },
    async validateNellyInfos() {
      this.checkPending = true;
      try {
        const result = await validateNellyCredentials(this.nellyDaten);
        if (result) {
          this.nellyCredentialsValidationStatus = result.success;
          this.nellyCredentialsValidationError = result.error;
        } else {
          this.nellyCredentialsValidationStatus = false;
          this.nellyCredentialsValidationError = 'Fehler bei Verbindung';
        }
      } catch (err) {
        this.nellyCredentialsValidationStatus = false;
        this.nellyCredentialsValidationError = `Fehler bei Verbindung: ${err.message}`;
        console.error(err);
      } finally {
        this.checkPending = false;
      }
    },
    async validateFabiusInfos() {
      this.checkPending = true;
      try {
        const result = await validateFabiusCredentials(this.fabiusDaten);
        if (result) {
          this.fabiusCredentialsValidationStatus = result.success;
          this.fabiusCredentialsValidationError = result.error;
        } else {
          this.fabiusCredentialsValidationStatus = false;
          this.fabiusCredentialsValidationError = 'Fehler bei Verbindung';
        }
      } catch (err) {
        this.fabiusCredentialsValidationStatus = false;
        this.fabiusCredentialsValidationError = `Fehler bei Verbindung: ${err.message}`;
        console.error(err);
      } finally {
        this.checkPending = false;
      }
    },
    async startObservingPvsDentalRegistration() {
      this.pvsdentalWaitForRegistration = 'wait';
      await this.startObservingPvsDentalRegistrationDoit();
    },
    async startObservingPvsDentalRegistrationDoit() {
      if (onboardingStore.state.r4cSecret) {
        while (this.pvsdentalWaitForRegistration === 'wait') {
          const result = await validatePvsDentalCredentials(onboardingStore.state.r4cSecret, this.pvsdentalDaten.token);
          if (result) {
            const fuckts = this.pvsdentalWaitForRegistration;
            if (fuckts !== 'cancel') {
              console.log(`validatePvsDentalCredentials`, onboardingStore.state.r4cSecret, this.pvsdentalDaten.token);
              this.pvsdentalWaitForRegistration = result.success ? 'success' : 'wait';
              this.teamfaktorCredentialsValidationStatus = result.success;
              this.teamfaktorCredentialsValidationError = result.error;
              this.pvsdentalDaten.token = result.token;
            }
          } else {
            this.teamfaktorCredentialsValidationStatus = false;
            this.teamfaktorCredentialsValidationError = 'Fehler bei Verbindung';
          }
          await sleep(2000);
        }
      }
      this.pvsdentalWaitForRegistration = 'idle';
    },
    getRzIconUrl(rzkey) {
      const images = require.context('../assets/', false, /\.png$/);
      const url = `./${rzkey}.png`;
      if (images.keys().includes(url)) {
        return images(url);
      }
    },
    toogleMarkForDeletion(rz) {
      rz.shouldDelete = !rz.shouldDelete;
    }
  }
});