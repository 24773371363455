import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import { defineComponent } from 'vue';
import { onboardingStore } from '@/state/onboardingStore';
import { isProd, isStaging } from '../../../common-ui';
import { PACKAGES, metricsPakete } from '../../../types';
import { keyBy } from 'lodash';
export default defineComponent({
  components: {},
  data: () => ({
    demo: true,
    pakete: metricsPakete,
    behandlerCount: 2,
    prophylaxeCount: 4,
    saveSetupFee: true,
    gutscheinCode: ''
  }),
  computed: {
    progressBarColor() {
      switch (this.selectedPaket.id) {
        case 'White':
          return '#f5f5f5';
        case 'Red':
          return '#e96382';
        case 'Black':
          return 'black';
        case 'Diamond':
          return '#c0c0c0';
        default:
          return '';
      }
    },
    roseLizenzen() {
      return +this.behandlerCount + +this.prophylaxeCount * 0.5;
    },
    selectedPaket() {
      if (this.roseLizenzen <= 2) {
        return this.pakete[0];
      }
      const lizenzen = Math.ceil(this.roseLizenzen);
      return this.pakete.find(paket => paket.min <= lizenzen && paket.max > lizenzen) || this.pakete[0];
    },
    zusatzLizenzen() {
      if (this.selectedPaket.id === 'Diamond') {
        return 0;
      }
      return Math.ceil(Math.max(this.roseLizenzen - this.selectedPaket.min, 0));
    },
    zusatzLizenzenGesamtPreis() {
      return this.zusatzLizenzen * this.selectedPaket.zusatzLizenzPreis;
    }
  },
  watch: {
    behandlerCount() {
      let c = +this.behandlerCount;
      if (isNaN(c)) {
        c = 1;
      }
      this.behandlerCount = Math.max(1, this.behandlerCount);
    },
    prophylaxeCount() {}
  },
  mounted() {
    // this view cannot be invalid
    this.$emit('viewValidUpdate', true);
    console.log('this.$route.query.paket', this.$route.query.paket);
    const byId = keyBy(this.pakete, 'id');
    if (this.$route.query.paket === 'white') {
      this.behandlerCount = byId[PACKAGES.WHITE].min;
      this.prophylaxeCount = 1;
    } else if (this.$route.query.paket === 'red') {
      this.behandlerCount = byId[PACKAGES.RED].min;
      this.prophylaxeCount = 2;
    } else if (this.$route.query.paket === 'black') {
      this.behandlerCount = byId[PACKAGES.BLACK].min;
      this.prophylaxeCount = 4;
    } else if (this.$route.query.paket === 'diamond') {
      this.behandlerCount = byId[PACKAGES.DIAMOND].min;
      this.prophylaxeCount = 6;
    }
  },
  methods: {
    persistData() {
      onboardingStore.commit.setLizenzInfo({
        paket: this.selectedPaket,
        behandlerCount: this.behandlerCount,
        prophylaxeCount: this.prophylaxeCount,
        saveSetupFee: this.saveSetupFee,
        gutscheinCode: this.gutscheinCode
      });
    },
    next() {
      this.$emit('next');
      // since this was the first interaction add unload handler here
      if (isProd || isStaging) {
        window.onbeforeunload = () => 'Sind sie sicher, dass Sie den Vorgang abbrechen wollen?';
      }
    }
  }
});