import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap",
    attrs: {
      "id": "wrap"
    }
  }, [_c('h3', [_vm._v("rose Lizenzen")]), _c(VAlert, {
    staticClass: "mt-3",
    attrs: {
      "type": "success",
      "color": "blue"
    }
  }, [_vm._v("Wähle aus, wie viele Zahnärzte und Prophylaxemitarbeiter in der Praxis arbeiten.")]), _c('div', {
    staticClass: "countWrap"
  }, [_c('div', {
    staticClass: "countInput"
  }, [_c('div', {
    staticClass: "behandlerCount"
  }, [_c('div', {
    staticClass: "label"
  }, [_c('span', [_vm._v("Zahnärzte")]), _c(VTooltip, {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c(VIcon, _vm._g({
          staticClass: "ml-1",
          attrs: {
            "size": "20"
          }
        }, on), [_vm._v("mdi-help-circle-outline")])];
      }
    }])
  }, [_c('span', [_vm._v("Für jeden "), _c('b', [_vm._v("aktiven")]), _vm._v(" Zahnarzt sind 1,0 rose Lizenzen erforderlich. Jeder Behandler mit einem Honorarumsatz von > 500 EUR wird im laufenden Monat als aktiv gewertet. Die Ermittlung der aktiven Behandler findet monatlich automatisch statt.")])])], 1), _c(VSlider, {
    attrs: {
      "min": "1",
      "max": "20",
      "thumb-label": "",
      "hide-details": "",
      "ticks": ""
    },
    model: {
      value: _vm.behandlerCount,
      callback: function ($$v) {
        _vm.behandlerCount = $$v;
      },
      expression: "behandlerCount"
    }
  }), _c('div', {
    staticClass: "label",
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.behandlerCount))]), _vm.behandlerCount === 20 ? _c('span', [_vm._v("+")]) : _vm._e()]), _c('span', {
    staticClass: "hint"
  }, [_vm._v(" x1 rose Lizenz")]), _c('div', {
    staticClass: "label"
  }, [_c('span', [_vm._v("Prophylaxe")]), _c(VTooltip, {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c(VIcon, _vm._g({
          staticClass: "ml-1",
          attrs: {
            "size": "20"
          }
        }, on), [_vm._v("mdi-help-circle-outline")])];
      }
    }])
  }, [_c('span', [_vm._v("Für jede "), _c('b', [_vm._v("aktive")]), _vm._v(" Prophylaxekraft sind 0,5 rose Lizenzen erforderlich. Jeder Behandler mit einem Honorarumsatz von > 2000 EUR wird im laufenden Monat als aktiv gewertet. Die Ermittlung der aktiven Behandler findet monatlich automatisch statt.")])])], 1), _c(VSlider, {
    attrs: {
      "min": "0",
      "max": "20",
      "thumb-label": "",
      "hide-details": "",
      "ticks": ""
    },
    model: {
      value: _vm.prophylaxeCount,
      callback: function ($$v) {
        _vm.prophylaxeCount = $$v;
      },
      expression: "prophylaxeCount"
    }
  }), _c('div', {
    staticClass: "label",
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.prophylaxeCount))]), _vm.prophylaxeCount === 20 ? _c('span', [_vm._v("+")]) : _vm._e()]), _c('span', {
    staticClass: "hint"
  }, [_vm._v(" x0,5 rose Lizenzen")])], 1)])]), _c('div', [_c('div', {
    staticClass: "total text-center"
  }, [_c('span', [_vm._v("Insgesamt")]), _c('span', {
    staticClass: "highlight"
  }, [_vm._v(_vm._s(_vm.roseLizenzen))]), _c('span', [_vm._v("rose Lizenzen")])])]), _c('div', {
    staticClass: "row pakete"
  }, _vm._l(_vm.pakete, function (paket) {
    return _c('div', {
      staticClass: "col-lg-3 col-md-6 col-sm-12",
      class: {
        active: paket === _vm.selectedPaket
      }
    }, [_c('span', {
      class: {
        hint2: !(paket === _vm.selectedPaket)
      }
    }, [_vm._v(_vm._s(paket.min) + " - " + _vm._s(paket.max - 1) + " Lizenzen")]), _c('div', {
      staticClass: "paket",
      class: {
        disabled: !(paket === _vm.selectedPaket),
        [paket.class]: true
      }
    }, [_c('div', {
      staticClass: "paket-info"
    }, [_c('div', {
      staticClass: "paket-text"
    }, [_vm._v(_vm._s(paket.text1))]), _c('div', {
      staticClass: "paket-text"
    }, [_vm._v(_vm._s(paket.text2))]), _c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(paket.name))]), _c('div', {
      staticClass: "preis"
    }, [_vm._v(_vm._s(paket.preis) + " €")]), _c('div', {
      staticClass: "promonat"
    }, [_vm._v("Basispreis")])]), paket === _vm.selectedPaket ? _c('div', {
      staticClass: "choose"
    }, [_c('div', {
      staticClass: "paket-footer"
    }, [_vm.zusatzLizenzen ? _c('div', {
      staticClass: "zusatzLizenz"
    }, [_c('span', [_vm._v("+" + _vm._s(_vm.zusatzLizenzen) + " Zusatzlizenzen")]), _c('span', {
      staticClass: "highlight"
    }, [_vm._v(" " + _vm._s(_vm.zusatzLizenzenGesamtPreis) + "€")])]) : _vm._e()]), _c('div', {
      staticClass: "total-info"
    }, [_c('div', {
      staticClass: "preis"
    }, [_vm._v(" " + _vm._s(paket.preis + _vm.zusatzLizenzenGesamtPreis) + "€")]), _c('div', {
      staticClass: "promonat"
    }, [_vm._v(_vm._s(paket.promonat))])]), _c('div', {
      staticClass: "paket-footer"
    })]) : _vm._e()])]);
  }), 0), _c('h3', [_vm._v("Einrichtungsgebühr und Vertragslaufzeit")]), _c('div', {
    staticClass: "save-setup"
  }, [_c('div', {
    staticClass: "save-setup-choice",
    class: {
      "save-setup-active": !_vm.saveSetupFee
    },
    on: {
      "click": function ($event) {
        _vm.saveSetupFee = false;
      }
    }
  }, [_c('div', {
    staticClass: "line bold"
  }, [_vm._v("Vertragslaufzeit 1 Monat")]), _c('div', {
    staticClass: "line"
  }, [_vm._v("monatlich kündbar")]), _c('div', {
    staticClass: "line"
  }, [_vm._v("499€ einmalige Einrichtungsgebühr")])]), _c('div', {
    staticClass: "save-setup-choice",
    class: {
      "save-setup-active": _vm.saveSetupFee
    },
    on: {
      "click": function ($event) {
        _vm.saveSetupFee = true;
      }
    }
  }, [_c('div', {
    staticClass: "line bold"
  }, [_vm._v("Vertragslaufzeit 12 Monate")]), _c('div', {
    staticClass: "line"
  }, [_vm._v("nach 12 Monaten monatlich kündbar")]), _c('div', {
    staticClass: "line"
  }, [_vm._v("0€ einmalige Einrichtungsgebühr")])])]), _c('div', {
    staticClass: "weiter"
  }, [_c('div', {
    staticClass: "next"
  }, [_c(VBtn, {
    staticClass: "ma-2",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.next();
      }
    }
  }, [_vm._v("Weiter")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };