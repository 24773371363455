import { defineComponent } from 'vue';
import Root from '../components/Main.vue';
import { onboardingStore } from '@/state/onboardingStore';
import { OnboardingMetricsTask } from '@/state/tasks';
export default defineComponent({
  components: {
    Root
  },
  mounted() {
    onboardingStore.commit.initializeAppTask(OnboardingMetricsTask);
  }
});