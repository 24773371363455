import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import { defineComponent } from 'vue';
import { onboardingStore } from '@/state/onboardingStore';
import { StepId } from '@/state/tasks';
export default defineComponent({
  components: {},
  data: () => ({
    fullState: onboardingStore.state,
    StepId
  }),
  computed: {
    shownStepNumber: {
      get() {
        return onboardingStore.getters.shownStepsFlow.indexOf(onboardingStore.state.activeStep) + 1;
      },
      set(stepNumber) {
        onboardingStore.commit.setActiveStep(onboardingStore.getters.shownStepsFlow[stepNumber - 1].stepId);
      }
    },
    maxStepIndexReached() {
      return onboardingStore.state.maxStepIndexReached;
    },
    shownStepsFlow() {
      return onboardingStore.state.stepFlow;
    }
  },
  watch: {
    shownStepNumber(newValue, oldStepNumber) {
      const refs = this.$refs.activeView;
      if (!refs) {
        return;
      }
      // CALL REFRESH HOOK ON STEP
      const activeView = refs.find(view => view.$attrs.stepperIndex + 1 === this.shownStepNumber);
      if (activeView && activeView.refreshHook) {
        activeView.refreshHook();
      }
      // CALL PERSIST HOOK ON STEP
      const prevView = refs.find(view => view.$attrs.stepperIndex + 1 === oldStepNumber);
      if (prevView && prevView.persistData) {
        prevView.persistData();
      }
    }
  },
  methods: {
    viewValidUpdate(step, isValid) {
      console.log('viewValidupdate', isValid);
      onboardingStore.commit.setStepComplete({
        step,
        complete: isValid
      });
    },
    nextStep() {
      onboardingStore.commit.nextStep();
    },
    nextStepAccept(step) {
      onboardingStore.commit.setStepComplete({
        step,
        complete: true
      });
      onboardingStore.commit.nextStep();
    },
    prevStep(step) {
      onboardingStore.commit.prevStep();
    },
    goto(step) {
      onboardingStore.commit.setActiveStep(step);
    }
  }
});